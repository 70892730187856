import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import Clocking from "./clocking";
import {
	Row,
	Col,
	Alert,
} from "reactstrap"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
import { DateTime } from "luxon";
const Index = (props) => {
	const breadcrumbItems = [
		// { title: "Lexa", link: "#" },
		// { title: "Usuarios", link: "#" }
	]
	const [clocking, setClocking] = useState([]);
	const [users, setUsers] = useState([]);

	const [error, setError] = useState("")
	const [success, setSuccess] = useState("")

	const [userFilter, setUserFilter] = useState('0')
	const [startDateFilter, setStartDateFilter] = useState('')
	const [endDateFilter, setEndDateFilter] = useState('')
	const [filtersChange, setFiltersChange] = useState(true)

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [perPage, setPerPage] = useState(15);
	const [totalResults, setTotalResults] = useState(15);
	useEffect(() => {
		if (startDateFilter === '') {
			const now = DateTime.now().setZone('Europe/Madrid');
			const date = now.toFormat('yyyy-MM-dd');
			setStartDateFilter(date);
			setEndDateFilter(date);
		}
	});

	useEffect(() => {
		props.setBreadcrumbItems('Fichaje', breadcrumbItems);
		fetchDataUsers();
	}, []);
	useEffect(() => {
		if (filtersChange) {
			fetchDataClocking(currentPage);
		}
		setFiltersChange(false)
	}, [filtersChange]);

	const fetchDataUsers = async () => {
		try {
			const authToken = localStorage.getItem("authToken");
			const headers = {
				Authorization: `Bearer ${authToken}`,
			};
			const response = await fetch("https://backend.jaenvivienda.com/api/users/table", { headers });
			if (response.ok) {
				const data = await response.json();
				if (data.statusCode === 200) {
					setUsers(data.data);
				}
			} else {
				console.error("Error al obtener la lista de usuarios");
			}
		} catch (error) {
			console.error("Error al obtener la lista de usuarios:", error);
		}
	};
	const fetchDataClocking = async () => {
		try {
			const authToken = localStorage.getItem("authToken");
			const headers = {
				Authorization: `Bearer ${authToken}`,
			};
			const url = `https://backend.jaenvivienda.com/api/clocking/filter?page=${currentPage}&user=${userFilter}&start=${startDateFilter}&end=${endDateFilter}`;
			const response = await fetch(url, { headers });

			if (response.ok) {
				const data = await response.json();
				if (data.statusCode === 200) {
					setClocking(data.data.data);
					setCurrentPage(data.data.current_page);
					setTotalPages(data.data.last_page);
					setPerPage(data.data.per_page);
					setTotalResults(data.data.total);
				}
			} else {
				console.error("Error alasaobtener los registros de fichaje");
			}
		} catch (error) {
			console.error("Error al obtener los registros de fichaje:", error);
		}
	};

	return (
		<React.Fragment>
			<MetaTags>
				<title>Fichaje | Jaenvivienda</title>
			</MetaTags>
			<Row>
				<Col lg="12">
					{error !== "" ? (
						<Alert color="danger">{error}</Alert>
					) : null}
					{success !== "" ? (
						<Alert color="success">{success}</Alert>
					) : null}
				</Col>
				<Col xl="12">
					<Clocking
						clocking={clocking}
						users={users}
						setError={setError}
						setSuccess={setSuccess}
						userFilter={userFilter}
						startDateFilter={startDateFilter}
						endDateFilter={endDateFilter}
						setUserFilter={setUserFilter}
						setStartDateFilter={setStartDateFilter}
						setEndDateFilter={setEndDateFilter}
						setFiltersChange={setFiltersChange}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						totalPages={totalPages}
						perPage={perPage}
						totalResults={totalResults}
					/>
				</Col>
			</Row>

		</React.Fragment>
	)
}

export default connect(null, { setBreadcrumbItems })(Index);