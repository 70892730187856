import React, { useState } from 'react';
import { Table, Card, CardBody, Button } from "reactstrap";
import UserImage from './user-image'
import { Link } from "react-router-dom";

function formatDate(dateStr) {
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
}
function formatClocking(dateStr) {
    const date = new Date(dateStr);
    // Formatea la fecha
    const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
}
const Users = ({ users, setError, setSuccess }) => {
    const [deletedUsers, setDeletedUsers] = useState([]); // Usuarios eliminados, para ocultarlos
    const deleteUser = async (e, id, userName, userSurnames) => {
        e.preventDefault();
        const confirmation = window.confirm(`¿Estás seguro de que quieres eliminar a ${userName} ${userSurnames}?`);
        if (confirmation) {
            const authToken = localStorage.getItem("authToken");
            const url = "https://backend.jaenvivienda.com/api/users/" + id;
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.statusCode === 200) {
                    setSuccess("Trabajador eliminado con éxito.");
                    setError("");
                    setDeletedUsers([...deletedUsers, id]);
                } else {
                    setError("Error al eliminar el trabajador.");
                    setSuccess("");
                }
            }
        } else {
            console.log("Eliminación cancelada.");
        }
    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-4">Trabajadores</h4>
                    <div className="table-responsive">
                        <Table className="align-middle table-centered table-vertical table-nowrap">
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Correo Electrónico</th>
                                    <th>DNI</th>
                                    <th>Teléfono</th>

                                    <th>Fichaje</th>
                                    <th>Fecha de registro</th>
                                    <th>Editar</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.length > 0 ? (
                                    users.map((user, key) => (
                                        !deletedUsers.includes(user.id) &&
                                        (
                                            <tr key={key}>
                                                <td>
                                                    <UserImage imageRoute={user.image_route} />
                                                    {" "}
                                                    {user.name} {user.surnames}
                                                </td>
                                                <td>
                                                    {user.email}
                                                </td>
                                                <td>
                                                    {user.dni}
                                                </td>
                                                <td>
                                                    {user.phone}
                                                </td>
                                                <td>
                                                    {user.clocking[0] && (
                                                        <>
                                                            <i className={`mdi mdi-checkbox-blank-circle text-${user.clocking[0].end_datetime ? 'danger' : 'success'}`}></i>{" "}
                                                            {user.clocking[0].end_datetime ? 'Finalizada: ' : 'En curso desde'}
                                                            <br />
                                                            {formatClocking(user.clocking[0].end_datetime || user.clocking[0].start_datetime)}
                                                        </>
                                                    )}
                                                    {!user.clocking[0] && (
                                                        <>
                                                            <i className="mdi mdi-checkbox-blank-circle text-secondary"></i>{" "} No hay registros
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    {formatDate(user.created_at)}
                                                </td>
                                                {/* <td>
                                            ${user.amount}
                                            <p className="m-0 text-muted font-14">Amount</p>
                                        </td> */}
                                                {/* <td>
                                            {user.date}
                                            <p className="m-0 text-muted font-14">Date</p>
                                        </td> */}
                                                <td>
                                                    <Link to={`users/${user.id}`}>
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="waves-effect waves-light"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </Link>
                                                </td>
                                                <td>
                                                    {!user.clocking[0] && (
                                                        <Button
                                                            color="danger"
                                                            size="sm"
                                                            className="waves-effect waves-light"
                                                            onClick={(e) => deleteUser(e, user.id, user.name, user.surnames)}
                                                        >
                                                            Eliminar
                                                        </Button>)}
                                                    {user.clocking[0] && (
                                                        <div>
                                                            No es posible,
                                                            <br />
                                                            tiene registros
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    ))) : (
                                    <tr>
                                        <td colSpan="7">No se encontraron usuarios.</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default Users;