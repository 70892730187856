import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import UserImage from './user-image'

import {
	Row,
	Col,
	Card,
	Alert,
	CardBody,
	Media,
	Button,
} from "reactstrap"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"
import avatar from "../../assets/images/users/no-user.jpg"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const UserProfile = props => {
	const [id, setId] = useState(props.computedMatch.params.id)
	const [email, setEmail] = useState("")
	const [name, setName] = useState("")
	const [surnames, setSurnames] = useState("")
	const [imageRoute, setImageRoute] = useState("")
	const [dni, setDni] = useState("")
	const [phone, setPhone] = useState("")
	const [job, setJob] = useState("")
	const [password, setPassword] = useState(null)
	const [password_verify, setPassword_verify] = useState(null)
	const [error, setError] = useState("")
	const [success, setSuccess] = useState("")
	const [isSubmitting, setIsSubmitting] = useState(false);

	const history = useHistory();
	const messages = {
		required: "Este campo es obligatorio",
		min: "El valor mínimo permitido es de :min caracteres",
		max: "El valor máximo permitido es de :max caracteres",
		min2: "El valor mínimo permitido es de 2 caracteres",
		max20: "El valor máximo permitido es de 20 caracteres",
		max40: "El valor máximo permitido es de 40 caracteres",
		pattern: "El formato del campo no es válido",
		noNumeric: "El campo no puede contener caracteres numéricos",
		password: "La contraseña debe tener mínimo 9 caracteres y al menos: una letra minúscula, una letra mayúscula, un número y un caracter especial."
	};

	const authToken = localStorage.getItem("authToken");
	const headers = {
		Authorization: `Bearer ${authToken}`,
	};

	useEffect(() => {
		props.setBreadcrumbItems('Perfil', breadcrumbItems);
		const fetchData = async () => {
			try {
				if (id != 0) {
					const url = 'https://backend.jaenvivienda.com/api/users/' + id;
					const response = await fetch(url, { headers });
					if (response.ok) {
						const data = await response.json();
						if (data.statusCode === 200) {
							setName(data.data.name);
							setEmail(data.data.email);
							setSurnames(data.data.surnames);
							setDni(data.data.dni);
							setPhone(data.data.phone);
							setJob(data.data.job);
							setImageRoute(data.data.image_route)
						} else {
							history.push('/users');
						}
					} else {
						console.error("Error al obtener la lista de usuarios");
					}
				}
			} catch (error) {
				console.error("Error al obtener la lista de usuarios:", error);
			}
		};
		fetchData();
	}, []);


	const breadcrumbItems = [
		{ title: "Trabajadores", link: "/users" },
		{ title: "Perfil", link: "" },
	]

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSuccess("");
		setError("");
		setIsSubmitting(true);
		let formData;
		if (id == 0 || password) {
			formData = {
				name,
				surnames,
				email,
				dni,
				phone,
				job,
				password,
				password_verify
			};
		} else {
			formData = {
				name,
				surnames,
				email,
				dni,
				phone,
				job
			};
		}
		try {
			let url, method;
			if (id == 0) {
				url = "https://backend.jaenvivienda.com/api/users";
				method = "POST"
			} else {
				url = "https://backend.jaenvivienda.com/api/users/" + id;
				method = "PATCH"
			}
			const response = await fetch(url, {
				method: method,
				headers: {
					...headers,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				const data = await response.json();
				if (data.statusCode === 200) {
					if (id == 0) {
						setSuccess("Trabajador creado con éxito.");
						setId(data.data.id)
					} else {
						setSuccess("Trabajador editado con éxito.");
					}
					history.push('/users/' + id);
				} else {
					let text = 'crear';
					if (id != 0) {
						text = 'editar';
					}
					if (data.data.email) {
						setError("Error al " + text + " el trabajador, el email ya está registrado y no puede duplicarse");
					} else if (data.data.dni) {
						setError("Error al " + text + " el trabajador, el DNI ya está registrado y no puede duplicarse");
					} else {
						setError("Error al " + text + " el trabajador.");
					}
				}
			}
		} catch (error) {
			// Manejar errores generales
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			<MetaTags>
				<title>Formulario trabajador | Jaenvivienda</title>
			</MetaTags>
			<Row>
				<Col lg="12">
					{error !== "" ? (
						<Alert color="danger">{error}</Alert>
					) : null}
					{success !== "" ? (
						<Alert color="success">{success}</Alert>
					) : null}
					{id != 0 && (
						<Card>
							<CardBody>
								<Media>
									<div className="me-3">
										<UserImage imageRoute={imageRoute} otherClass='avatar-md rounded-circle img-thumbnail' />
										{/* <img
											src={avatar}
											alt=""
											className="avatar-md rounded-circle img-thumbnail"
										/> */}
									</div>
									<Media body className="align-self-center">
										<div className="text-muted">
											<h5>{name} {surnames}</h5>
											<p className="mb-1">{email}</p>
											<p className="mb-0">Id no: #{id}</p>
										</div>
									</Media>
								</Media>
							</CardBody>
						</Card>)
					}
				</Col>
			</Row>

			<h4 className="card-title mb-4">Datos del trabajador</h4>

			<Card>
				<CardBody>
					<AvForm
						className="form-horizontal"
						onValidSubmit={(e, v) => {
							if (id === 0 || password || password_verify) {
								if (password === password_verify) {
									handleSubmit(e, v);
								} else {
									alert("Las contraseñas no coinciden. Por favor, vuelve a intentarlo.");
								}
							} else {
								handleSubmit(e, v);
							}
						}}
					>
						<Row>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="name"
										label="Nombre*"
										value={name}
										className="form-control"
										placeholder="Intoduce el nombre"
										type="text"
										onChange={(e) => setName(e.target.value)}
										validate={{
											required: { value: true, errorMessage: messages.required },
											minLength: { value: 2, errorMessage: messages.min2 },
											maxLength: { value: 20, errorMessage: messages.max20 },
											pattern: { value: /^[^0-9]*$/, errorMessage: messages.noNumeric },
										}}
									/>
									{/* <AvField name="id" value={id} type="hidden" /> */}
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="surnames"
										label="Apellidos*"
										value={surnames}
										className="form-control"
										placeholder="Intoduce los apellidos"
										type="text"
										onChange={(e) => setSurnames(e.target.value)}
										validate={{
											required: { value: true, errorMessage: messages.required },
											minLength: { value: 2, errorMessage: messages.min2 },
											maxLength: { value: 40, errorMessage: messages.max40 },
											pattern: { value: /^[^0-9]*$/, errorMessage: messages.noNumeric },
										}}
									/>
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="email"
										label="Correo Electrónico*"
										value={email}
										className="form-control"
										placeholder="Intoduce el correo electrónico"
										type="email"
										onChange={(e) => setEmail(e.target.value)}
										validate={{
											required: { value: true, errorMessage: messages.required },
											pattern: {
												value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
												errorMessage: "Introduce un formato de email válido",
											},
										}}
									/>
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="dni"
										label="DNI*"
										value={dni}
										className="form-control"
										placeholder="Intoduce el DNI"
										type="text"
										onChange={(e) => setDni(e.target.value)}
										validate={{
											required: { value: true, errorMessage: messages.required },
											pattern: {
												value: /^[0-9]{8}[A-Z]$/,
												errorMessage: "Introduce un DNI válido",
											},
										}}
									/>
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="phone"
										label="Número de teléfono*"
										value={phone}
										className="form-control"
										placeholder="Intoduce el número de teléfono"
										type="text"
										onChange={(e) => setPhone(e.target.value)}
										validate={{
											required: { value: true, errorMessage: messages.required },
											pattern: {
												value: /^\+?[0-9]{9,11}$/,
												errorMessage: messages.pattern,
											},
										}}
									/>
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="job"
										label="Puesto de trabajo*"
										value={job}
										className="form-control"
										placeholder="Intoduce el puesto de trabajo"
										type="text"
										onChange={(e) => setJob(e.target.value)}
										validate={{
											required: { value: true, errorMessage: messages.required },
											pattern: { value: /^[^0-9]*$/, errorMessage: messages.noNumeric },
										}}
									/>
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="password"
										label={id == 0 ? "Contraseña*" : "Contraseña"}
										value={password}
										className="form-control"
										placeholder="Intoduce la contraseña"
										type="password"
										onChange={(e) => setPassword(e.target.value)}
										validate={{
											required: { value: id == 0, errorMessage: messages.required },
											pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).+$/, errorMessage: messages.password },
										}}
									/>
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="password_verify"
										label={id == 0 ? "Confirmación de contraseña*" : "Confirmación de contraseña"}
										value={password_verify}
										className="form-control"
										placeholder="Repite la contraseña"
										type="password"
										onChange={(e) => setPassword_verify(e.target.value)}
										validate={{
											required: { value: id == 0 || password, errorMessage: messages.required },
											pattern: { value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).+$/, errorMessage: messages.password },
										}}
									/>
								</div>
							</Col>
						</Row>
						<div className="text-center mt-4">
							{id == 0 && (
								<Button type="submit" color="success" disabled={isSubmitting}>
									{isSubmitting ? "Enviando..." : "Crear trabajador"}
								</Button>
							)}
							{id != 0 && (
								<Button type="submit" color="success" disabled={isSubmitting}>
									{isSubmitting ? "Enviando..." : "Guardar cambios"}
								</Button>
							)}
						</div>
					</AvForm>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}

const mapStatetoProps = state => {
	const { error, success } = state.Profile
	return { error, success }
}

export default withRouter(
	connect(mapStatetoProps, { setBreadcrumbItems })(UserProfile)
)