import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Verificar la autenticación cuando el componente se monta
    const checkAuthentication = async () => {
      try {
        const authToken = localStorage.getItem("authToken");

        if (authToken) {
          const response = await fetch(
            "https://backend.jaenvivienda.com/api/backend/check-authentication",
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );
          if (response.ok) {
            const responseData = await response.json();
            if (responseData.statusCode === 200) {
              setIsAuthenticated(true);
            } else {
              setIsAuthenticated(false);
            }
          } else {
            setIsAuthenticated(false);
          }
        } else {
          setIsAuthenticated(false);
        }
        setLoading(false);
      } catch (error) {
        setIsAuthenticated(false);
        setLoading(false);
      }
    };

    if (isAuthProtected) {
      checkAuthentication();
    } else {
      setLoading(false);
    }
  }, [isAuthProtected]);

  if (loading) {
    // Muestra un indicador de carga mientras se verifica la autenticación
    return <div>Cargando...</div>;
  }

  if (isAuthProtected && !isAuthenticated) {
    // Si la ruta es protegida y el usuario no está autenticado, redirige al inicio de sesión
    return (
      <Redirect
        to={{ pathname: "/login", state: { from: rest.location } }}
      />
    );
  }

  return (
    <Layout>
      <Component {...rest} />
    </Layout>
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
