import React, { useEffect, useState } from "react"
import MetaTags from 'react-meta-tags';
import { connect } from "react-redux";
import Users from "./users";
import {
	Row,
	Col,
	Alert,
} from "reactstrap"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

const Index = (props) => {

	const breadcrumbItems = [
		// { title: "Lexa", link: "#" },
		// { title: "Usuarios", link: "#" }
	]
	const [users, setUsers] = useState([]);
	const [error, setError] = useState("")
	const [success, setSuccess] = useState("")
	useEffect(() => {
		props.setBreadcrumbItems('Listado de Trabajadores', breadcrumbItems);
		const fetchData = async () => {
			try {
				const authToken = localStorage.getItem("authToken");
				const headers = {
					Authorization: `Bearer ${authToken}`,
				};
				const response = await fetch("https://backend.jaenvivienda.com/api/users/table", { headers });
				if (response.ok) {
					const data = await response.json();
					if (data.statusCode === 200) {
						setUsers(data.data);
					}
				} else {
					console.error("Error al obtener la lista de usuarios");
				}
			} catch (error) {
				console.error("Error al obtener la lista de usuarios:", error);
			}
		};
		fetchData();
	}, []);

	return (
		<React.Fragment>

			<MetaTags>
				<title>Trabajadores | Jaenvivienda</title>
			</MetaTags>
			<Row>
				<Col lg="12">
					{error !== "" ? (
						<Alert color="danger">{error}</Alert>
					) : null}
					{success !== "" ? (
						<Alert color="success">{success}</Alert>
					) : null}
				</Col>
				<Col xl="12">
					<Users users={users} setError={setError} setSuccess={setSuccess} />
				</Col>
			</Row>

		</React.Fragment>
	)
}

export default connect(null, { setBreadcrumbItems })(Index);