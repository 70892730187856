import React, { useState, useEffect } from 'react';
import { Table, Card, CardBody, Button, Row, Col, Tooltip } from "reactstrap";
import UserImage from '../Users/user-image'
import { Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation"

function formatDate(dateStr) {
    if (dateStr === null) return '';
    const date = new Date(dateStr);
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return date.toLocaleDateString('es-ES', options);
}

function formatTime(dateStr) {
    if (dateStr === null) return '';
    const date = new Date(dateStr);
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

    return date.toLocaleTimeString('es-ES', options);
}

const Clocking = ({
    clocking,
    users,
    setError,
    setSuccess,
    userFilter,
    startDateFilter,
    endDateFilter,
    setUserFilter,
    setStartDateFilter,
    setEndDateFilter,
    setFiltersChange,
    currentPage,
    setCurrentPage,
    totalPages,
    perPage,
    totalResults }) => {
    const [deletedClocking, setDeletedClocking] = useState([]);
    const messages = {
        required: "Este campo es obligatorio",
    };
    const [startTooltipOpen, setStartTooltipOpen] = useState(Array(clocking.length).fill(false));
    const startToggleTooltip = (index) => {
        const newTooltipOpen = [...startTooltipOpen];
        newTooltipOpen[index] = !newTooltipOpen[index];
        setStartTooltipOpen(newTooltipOpen);
    };
    const [endTooltipOpen, setEndTooltipOpen] = useState(Array(clocking.length).fill(false));
    const endToggleTooltip = (index) => {
        const newTooltipOpen = [...endTooltipOpen];
        newTooltipOpen[index] = !newTooltipOpen[index];
        setEndTooltipOpen(newTooltipOpen);
    };
    const [images, setImages] = useState([]);
    useEffect(() => {
        const loadImages = async () => {
            if (users) {
                try {
                    const authToken = localStorage.getItem("authToken");
                    users.forEach(async (user) => {
                        if (user.image_route) {
                            const url = `https://backend.jaenvivienda.com/api/users/${user.image_route}`;
                            const response = await fetch(url, {
                                method: "GET",
                                headers: {
                                    Authorization: `Bearer ${authToken}`,
                                },
                            });

                            if (response.ok) {
                                const responseData = await response.json();
                                if (responseData.statusCode === 200) {
                                    // Actualiza el estado de la imagen para el usuario actual
                                    setImages((prevImages) => ({
                                        ...prevImages,
                                        [user.id]: 'data:image/jpeg;base64,' + responseData.data,
                                    }));
                                }
                            }
                        }
                    });
                } catch (error) {
                    console.error("Error al cargar las imágenes:", error);
                }
            }
        };
        loadImages();
    }, [users]);
    const deleteClocking = async (e, key, id, userName, userSurnames) => {
        e.preventDefault();
        setSuccess('')
        setError('')
        const confirmation = window.confirm(`¿Estás seguro de que quieres eliminar el fichaje ${key} de ${userName} ${userSurnames}?`);
        if (confirmation) {
            const authToken = localStorage.getItem("authToken");
            const url = "https://backend.jaenvivienda.com/api/clocking/" + id;
            const response = await fetch(url, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.statusCode === 200) {
                    setSuccess("Fichaje eliminado con éxito.");
                    setError("");
                    setDeletedClocking([...deletedClocking, id]);
                } else {
                    setError("Error al eliminar el fichaje.");
                    setSuccess("");
                }
            }
        } else {
            console.log("Eliminación cancelada.");
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setSuccess('')
        setError('')
        setCurrentPage('1');
        setFiltersChange(true);
    };
    const changePage = (operator) => {
        setSuccess('')
        setError('')
        let page = currentPage;
        if (operator === '+') {
            page++;
        } else {
            page--;
        }
        setCurrentPage(page);
        setFiltersChange(true);
    };
    const operationKey = perPage * (currentPage - 1);
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <h4 className="card-title mb-2">Fichaje</h4>
                    <div className="table-responsive">
                        <div className="filters">
                            <AvForm className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    handleSubmit(e, v);
                                }}
                            >
                                <Row className="mb-4">
                                    <Col sm="6" md="3" className="mt-3">
                                        <AvField
                                            type="select"
                                            name="userFilter"
                                            value={userFilter}
                                            onChange={(e) => setUserFilter(e.target.value)}
                                            label="Filtrar por Trabajador"
                                            validate={{
                                                required: { value: true, errorMessage: messages.required },
                                            }} >
                                            {users.map((user) => (
                                                <option value={user.id} key={user.id}>{user.name} {user.surnames}</option>
                                            ))}
                                            <option value='0'>Todos</option>
                                        </AvField>
                                    </Col>
                                    <Col sm="6" md="3" className="mt-3">
                                        <AvField
                                            name="startDateFilter"
                                            label="Filtrar desde"
                                            type="date"
                                            value={startDateFilter}
                                            onChange={(e) => setStartDateFilter(e.target.value)}
                                            validate={{
                                                required: { value: true, errorMessage: messages.required },
                                            }}
                                        />
                                    </Col>
                                    <Col sm="6" md="3" className="mt-3">
                                        <AvField
                                            name="endDateFilter"
                                            label="Filtrar hasta"
                                            type="date"
                                            value={endDateFilter}
                                            onChange={(e) => setEndDateFilter(e.target.value)}
                                            validate={{
                                                required: { value: true, errorMessage: messages.required },
                                            }}
                                        />
                                    </Col>
                                    <Col sm="6" md="3" className="mt-3 align-self-end">
                                        <Button type="submit" color="primary">
                                            Buscar
                                        </Button>
                                    </Col>
                                </Row>
                            </AvForm>
                            {totalResults} resultados
                        </div>
                        <Table className="align-middle table-centered table-vertical table-nowrap">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Trabajador</th>
                                    <th>Fecha</th>
                                    <th>Entrada</th>
                                    <th>Salida</th>
                                    <th>Editar</th>
                                    <th>Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clocking.length > 0 ? (
                                    clocking.map((clock, key) => (
                                        !deletedClocking.includes(clock.id) &&
                                        (
                                            <tr key={operationKey + (key + 1)}>
                                                <td>
                                                    {operationKey + (key + 1)}
                                                </td>
                                                <td>
                                                    <UserImage imageUrl={images[clock.id_user]} />
                                                    {" "}
                                                    {clock.name} {clock.surnames}
                                                </td>
                                                <td>
                                                    {formatDate(clock.start_datetime)}
                                                </td>
                                                <td>
                                                    {formatTime(clock.start_datetime)}
                                                    {clock.start_street && (<>
                                                        <i
                                                            className="mdi mdi-google-maps"
                                                            style={{ color: '#7a6fbe', fontSize: '18px' }}
                                                            id={`startTooltip${key}`}
                                                            onMouseOver={() => startToggleTooltip(key)}
                                                            onMouseOut={() => startToggleTooltip(key)}
                                                        />
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={startTooltipOpen[key]}
                                                            target={`startTooltip${key}`}
                                                            toggle={() => startToggleTooltip(key)}
                                                            autohide={false}
                                                        >
                                                            {clock.start_street}, {clock.start_city}({clock.start_state_district}), {clock.start_region}, {clock.start_country}
                                                        </Tooltip>
                                                    </>
                                                    )}
                                                </td>
                                                <td>
                                                    {formatTime(clock.end_datetime)}
                                                    {clock.end_street && (<>
                                                        <i
                                                            className="mdi mdi-google-maps"
                                                            style={{ color: '#7a6fbe', fontSize: '18px' }}
                                                            id={`endTooltip${key}`}
                                                            onMouseOver={() => endToggleTooltip(key)}
                                                            onMouseOut={() => endToggleTooltip(key)}
                                                        />
                                                        <Tooltip
                                                            placement="top"
                                                            isOpen={endTooltipOpen[key]}
                                                            target={`endTooltip${key}`}
                                                            toggle={() => endToggleTooltip(key)}
                                                            autohide={false}
                                                        >
                                                            {clock.end_street}, {clock.end_city}({clock.end_state_district}), {clock.end_region}, {clock.end_country}
                                                        </Tooltip>
                                                    </>
                                                    )}
                                                </td>
                                                <td>
                                                    <Link to={`clocking/${clock.id}`}>
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="waves-effect waves-light"
                                                        >
                                                            Editar
                                                        </Button>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        className="waves-effect waves-light"
                                                        onClick={(e) => deleteClocking(e, operationKey + (key + 1), clock.id, clock.name, clock.surnames)}
                                                    >
                                                        Eliminar
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    ))) : (
                                    <tr>
                                        <td colSpan="7">No se encontraron registros de fichaje con estos filtros.</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <Row className="my-3 pagination justify-content-beteewn">
                            <Col sm='6'>
                                {currentPage != 1 && (
                                    <Button
                                        color={currentPage === 1 ? "secondary" : "primary"}
                                        size="md"
                                        className="waves-effect waves-light"
                                        onClick={() => changePage('-')}
                                        disabled={currentPage === 1}
                                    >
                                        Más recientes
                                    </Button>
                                )}
                            </Col>
                            <Col sm='6' style={{ textAlign: 'right', paddingRight: '30px' }}>
                                {currentPage != totalPages && (
                                    <Button
                                        color={currentPage === totalPages ? "secondary" : "primary"}
                                        size="md"
                                        className="waves-effect waves-light"
                                        onClick={() => changePage('+')}
                                        disabled={currentPage === totalPages}
                                    >
                                        Más antiguas
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default Clocking;
