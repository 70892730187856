import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logoLightPng from "../../assets/images/logo-sm.png"
import logoDark from "../../assets/images/logo-sm.png"

const Login = props => {
  const [loginError, setLoginError] = useState(null); 
  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      props.history.push("/users"); 
    }
  }, [props.history]);
  // handleValidSubmit
  const handleValidSubmit = async (event, values) => {
    try {
      const response = await fetch('https://backend.jaenvivienda.com/api/backend/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        body: JSON.stringify({
          usernamebackend: values.usernamebackend,
          password: values.password,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        if (responseData.statusCode === 200) {
          const authToken = responseData.data.token;
          localStorage.setItem("authToken", authToken);
          // Redirige a la página de inicio u otras acciones según tus necesidades
          props.history.push('/users');
        } else {
          setLoginError("El usuario o contraseña son incorrectos");
        }
      }
    } catch (error) {
      setLoginError("Error en el inicio de sesión");
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Jaenvivienda</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="100" className="auth-logo-dark" />
                      <img src={logoLightPng} alt="" height="100" className="auth-logo-light" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}
                      {loginError && (
                        <Alert color="danger">{loginError}</Alert>
                      )}
                      <div className="mb-3">
                        <AvField
                          name="usernamebackend"
                          label="Usuario"
                          className="form-control"
                          placeholder="Introduce el nombre de usuario"
                          type="text"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Contraseña"
                          type="password"
                          required
                          placeholder="Introduce la contraseña"
                        />
                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-12 text-center">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Iniciar sesión</button>
                        </div>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}