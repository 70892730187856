import React from "react"
import { Redirect, Route } from "react-router-dom"

// Profile
// import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import UsersTable from "../pages/Users/index"
import ClockingTable from "../pages/Clocking/index"
import UserProfile from "../pages/Users/user-profile"
import ClockingForm from "../pages/Clocking/clocking-form"
import Pages404 from "../pages/pages-404"
const userRoutes = [
  { path: "/users", component: UsersTable },
  { path: "/users/:id", component: UserProfile },
  { path: "/clocking", component: ClockingTable },
  { path: "/clocking/:id", component: ClockingForm },
  { path: "/", exact: true, component: () => <Redirect to="/users" /> },
  // { path: "*", component: Pages404 },
]
const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]


export { userRoutes, authRoutes }