import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

class Politicas extends Component {

    render() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Política de Privacidad | Clocking App Jaenvivienda</title>
                </MetaTags>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col>
                                <h1>Política de Privacidad</h1>
                                <p><strong>Última actualización:</strong> ´28/10/2023</p>

                                <h2>Introducción</h2>
                                <p>Esta Política de Privacidad describe cómo la aplicación de fichaje de Jaenvivienda S.L. ("nosotros" o "la aplicación") recopila, utiliza y comparte información personal de los usuarios ("usuarios" o "usted") de nuestra aplicación móvil. Jaenvivienda S.L. es una empresa con sede en Jaén, Andalucía, España. Su privacidad es importante para nosotros, y nos comprometemos a proteger sus datos personales.</p>

                                <h2>Información que Recopilamos</h2>
                                <ol>
                                    <li><strong>Información de Registro:</strong> Para acceder a nuestra aplicación, los usuarios deben proporcionar su dirección de correo electrónico y una contraseña que será creada por el administrador a través de nuestra aplicación web. La contraseña es almacenada de forma segura en nuestro sistema.</li>
                                    <li><strong>Información de Fichaje:</strong> Cuando los usuarios utilizan la aplicación para registrar sus fichajes, recopilamos la hora y la fecha del fichaje, así como cualquier información adicional relacionada con el fichaje (por ejemplo, ubicación si se otorga permiso).</li>
                                </ol>

                                <h2>Cómo Utilizamos su Información</h2>
                                <p>Utilizamos la información recopilada para los siguientes fines:</p>
                                <ol>
                                    <li><strong>Fichaje y Registro:</strong> Utilizamos su dirección de correo electrónico y contraseña para permitirle acceder a la aplicación y registrar sus fichajes. La información de fichaje se utiliza para llevar un registro de las entradas y salidas de los usuarios.</li>
                                    <li><strong>Seguridad:</strong> La contraseña es almacenada de manera segura para garantizar la seguridad de su cuenta y evitar el acceso no autorizado.</li>
                                </ol>

                                <h2>Compartir su Información</h2>
                                <p>No compartiremos su información personal con terceros, excepto en las siguientes situaciones:</p>
                                <ol>
                                    <li><strong>Administrador de la Empresa:</strong> El administrador de Jaenvivienda S.L. tendrá acceso a la información de fichaje para gestionar las entradas y salidas de los empleados. Esta información se utiliza únicamente con fines laborales y de registro.</li>
                                    <li><strong>Cumplimiento Legal:</strong> Podemos divulgar su información en respuesta a una solicitud legal o para cumplir con una obligación legal.</li>
                                </ol>

                                <h2>Seguridad de la Información</h2>
                                <p>Tomamos medidas razonables para proteger su información personal, incluida la contraseña, de la pérdida, el uso indebido y el acceso no autorizado. Utilizamos prácticas de seguridad estándar de la industria para proteger sus datos.</p>

                                <h2>Cambios en esta Política de Privacidad</h2>
                                <p>Esta Política de Privacidad puede actualizarse ocasionalmente para reflejar cambios en nuestras prácticas o en la ley. Se le notificará de cualquier cambio importante y se le proporcionará la oportunidad de revisar y aceptar los nuevos términos.</p>

                                <h2>Contacto</h2>
                                <p>Si tiene alguna pregunta sobre esta Política de Privacidad o sobre la forma en que manejamos su información, contáctenos a través de desarrollo.jaenvivienda@gmail.com.</p>

                                <p>Al usar nuestra aplicación, usted acepta esta Política de Privacidad. Le recomendamos que la lea detenidamente y la revise periódicamente.</p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Politicas;