import {
    SET_BREADCRUMB_ITEMS
} from './actionTypes';

const initialState={
    title : "404 - Página no encontrada",
    breadcrumbItems : [
        // { title : "404 Not Found", link : "/users" },
        // { title : "Users", link : "#" },
        // { title : "Users", link : "#" },
    ],
}

const layout = (state=initialState,action) => {
    switch(action.type){
        case SET_BREADCRUMB_ITEMS:
            return {
              ...state,
              title: action.payload.title,
              breadcrumbItems: action.payload.items
            };

        default:
            state = {...state};
            break;
    }
    return state;
}

export default layout;