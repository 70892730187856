import PropTypes from 'prop-types'
import MetaTags from 'react-meta-tags';
import React, { useState, useEffect } from "react"
import {
	Row,
	Col,
	Card,
	Alert,
	CardBody,
	Button,
} from "reactstrap"
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"
// Redux
import { connect } from "react-redux"
import { withRouter, useHistory } from "react-router-dom"
//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";
function formatDate(dateStr) {
	if (dateStr === null) return '';
	const date = dateStr.split(" ");
	return (date[0])
}

function formatTime(dateStr) {
	if (dateStr === null) return '';
	const date = new Date(dateStr);
	const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

	return date.toLocaleTimeString('es-ES', options);
}
const ClockingForm = props => {
	const [id, setId] = useState(props.computedMatch.params.id)
	const [userId, setUserId] = useState(null)
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("")
	const [startTime, setStartTime] = useState("")
	const [endTime, setEndTime] = useState("")
	const [error, setError] = useState("")
	const [success, setSuccess] = useState("")
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [users, setUsers] = useState([]);

	const history = useHistory();
	const messages = {
		required: "Este campo es obligatorio",
		pattern: "El formato del campo no es válido",
	};

	const authToken = localStorage.getItem("authToken");
	const headers = {
		Authorization: `Bearer ${authToken}`,
	};
	const fetchDataUsers = async () => {
		try {
			const response = await fetch("https://backend.jaenvivienda.com/api/users/table", { headers });
			if (response.ok) {
				const data = await response.json();
				if (data.statusCode === 200) {
					setUsers(data.data);
				}
			} else {
				console.error("Error al obtener la lista de usuarios");
			}
		} catch (error) {
			console.error("Error al obtener la lista de usuarios:", error);
		}
	};
	const fetchData = async () => {
		try {
			if (id != 0) {
				const url = 'https://backend.jaenvivienda.com/api/clocking/' + id;
				const response = await fetch(url, { headers });
				if (response.ok) {
					const data = await response.json();
					if (data.statusCode === 200) {
						setUserId(data.data.id_user)
						setStartDate(formatDate(data.data.start_datetime));
						setStartTime(formatTime(data.data.start_datetime));
						if (data.data.end_datetime) {
							setEndDate(formatDate(data.data.end_datetime));
							setEndTime(formatTime(data.data.end_datetime));
						}
					} else {
						history.push('/clocking');
					}
				} else {
					console.error("Error al obtener el fichaje");
				}
			}
		} catch (error) {
			console.error("Error al obtener el fichaje:", error);
		}
	};
	useEffect(() => {
		props.setBreadcrumbItems('Fichaje', breadcrumbItems);
		fetchDataUsers();
		fetchData();
	}, []);

	const breadcrumbItems = [
		{ title: "Fichaje", link: "/clocking" },
		{ title: "Formulario de Fichaje", link: "" },
	]

	const handleSubmit = async (e) => {
		e.preventDefault();
		setSuccess("");
		setError("");
		setIsSubmitting(true);
		let formData;
		let start_datetime = startDate + ' ' + startTime;
		if (endDate === '') {
			formData = {
				start_datetime,
				id_user: userId
			};
		} else {
			let end_datetime = endDate + ' ' + endTime;
			formData = {
				start_datetime,
				end_datetime,
				id_user: userId
			};
		}
		try {
			let url, method;
			if (id == 0) {
				url = "https://backend.jaenvivienda.com/api/clocking";
				method = "POST"
			} else {
				url = "https://backend.jaenvivienda.com/api/clocking/" + id;
				method = "PATCH"
			}
			const response = await fetch(url, {
				method: method,
				headers: {
					...headers,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(formData),
			});

			if (response.ok) {
				const data = await response.json();
				if (data.statusCode === 200) {
					if (id == 0) {
						setSuccess("Fichaje creado con éxito.");
						setId(data.data.id)
					} else {
						setSuccess("Fichaje editado con éxito.");
					}
					history.push('/clocking/' + data.data.id);
				} else {
					setError("Error al crear el fichaje.");
				}
			}
		} catch (error) {
			console.error(error)
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<React.Fragment>
			<MetaTags>
				<title>Formulario fichaje | Jaenvivienda</title>
			</MetaTags>
			<Row>
				<Col lg="12">
					{error !== "" ? (
						<Alert color="danger">{error}</Alert>
					) : null}
					{success !== "" ? (
						<Alert color="success">{success}</Alert>
					) : null}
				</Col>
			</Row>

			<h4 className="card-title mb-4">Datos del fichaje</h4>

			<Card>
				<CardBody>
					<AvForm
						className="form-horizontal"
						onValidSubmit={(e, v) => {
							if (endDate === '') {
								handleSubmit(e, v);
							} else {
								const startDateTime = new Date(`${startDate}T${startTime}`);
								const endDateTime = new Date(`${endDate}T${endTime}`);
								if (endDateTime >= startDateTime) {
									handleSubmit(e, v);
								} else {
									alert("La fecha de entrada debe ser anterior a la de salida");
								}
							}
						}}
					>
						<Row>
							<Col sm="6" md="3" className="mt-3">
								<AvField
									type="select"
									name="id_user"
									value={userId}
									disabled={id != 0}
									onChange={(e) => setUserId(e.target.value)}
									label="Selecciona un trabajador"
									validate={{
										required: { value: true, errorMessage: messages.required },
									}} >
									<option>Seleccionar...</option>
									{users.map((user) => (
										<option value={user.id} key={user.id}>{user.name} {user.surnames}</option>
									))}
								</AvField>
							</Col>
						</Row>
						<Row>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="startDate"
										label="Fecha de entrada*"
										value={startDate}
										className="form-control"
										type="date"
										onChange={(e) => setStartDate(e.target.value)}
										validate={{
											required: { value: true, errorMessage: messages.required },
										}}
									/>
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="startTime"
										label="Hora de entrada*"
										value={startTime}
										className="form-control"
										type="time"
										onChange={(e) => setStartTime(e.target.value)}
										validate={{
											required: { value: true, errorMessage: messages.required },
										}}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="endDate"
										label="Fecha de salida"
										value={endDate}
										className="form-control"
										type="date"
										onChange={(e) => setEndDate(e.target.value)}
									/>
								</div>
							</Col>
							<Col sm="6" xl="4" className="mt-3">
								<div className="form-group">
									<AvField
										name="endTime"
										label="Hora de salida"
										value={endTime}
										className="form-control"
										type="time"
										onChange={(e) => setEndTime(e.target.value)}
										validate={{
											required: { value: endDate != '', errorMessage: messages.required },
										}}
									/>
								</div>
							</Col>
						</Row>
						<div className="text-center mt-4">
							{id == 0 && (
								<Button type="submit" color="success" disabled={isSubmitting}>
									{isSubmitting ? "Enviando..." : "Crear fichaje"}
								</Button>
							)}
							{id != 0 && (
								<Button type="submit" color="success" disabled={isSubmitting}>
									{isSubmitting ? "Enviando..." : "Guardar cambios"}
								</Button>
							)}
						</div>
					</AvForm>
				</CardBody>
			</Card>
		</React.Fragment>
	)
}
const mapStatetoProps = state => {
	const { error, success } = state.Profile
	return { error, success }
}
export default withRouter(
	connect(mapStatetoProps, { setBreadcrumbItems })(ClockingForm)
)