import React, { useEffect, useState } from "react"
import noUser from "../../assets/images/users/no-user.jpg";
const UserImage = ({ imageRoute, imageUrl, otherClass }) => {
    const [image, setImage] = useState(noUser);
    useEffect(() => {
        if (imageUrl) {
            setImage(imageUrl)
        }
    })
    useEffect(() => {
        if (!imageUrl && imageRoute) {
            const loadUserImage = async () => {
                try {
                    const authToken = localStorage.getItem("authToken");
                    const url = `https://backend.jaenvivienda.com/api/users/${imageRoute}`;
                    const response = await fetch(url, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${authToken}`,
                        },
                    });
                    if (response.ok) {
                        const responseData = await response.json();
                        if (responseData.statusCode === 200) {
                            setImage('data:image/jpeg;base64,' + responseData.data)
                        }
                    }
                } catch (error) {
                    console.error("Error al cargar la imagen:", error);
                }
            };

            loadUserImage();
        }
    }, [imageRoute]);

    return <img
        src={image}
        alt="user"
        className={otherClass ? otherClass : "avatar-xs rounded-circle me-2"}
    />;
};

export default UserImage;